## 27th of March

It's been 2 months since the last update! I'm still struggling to find support at KTH. Long story short - few at the department have blockchain in their scope.

I'm carrying on. I've just uploaded the latest plan that I'm working towards.

As an extended comment to the updated plan - I'm leaning towards going in the direction of creating node embeddings and clustering based on similarity of member interaction. Thinking I can potentially find fingerprints of a typical DAO member.

Will be uploading some graph analysis shortly, as I have begun looking at pure interactions between members.

## 28th of January

So, about 3 weeks since the last update. I've yet to receive a thumbs up from KTH, so no supervisor or examinor yet. I'm worried they'll say my idea is mute and that I should pivot. Therefor spent several weeks just working and diving into web3 at large.

- I've received some form submissions! And some POAPs have been distributed!

- [Sätra](satrabrunn.xyz) is as good as dead - since most of the multisigs took a step back, no one else has come forward, and I cannot shoulder the project while also writing my thesis.

- [BanklessDAO](https://www.bankless.community/) is on my radar and I've been active at the Swedish sub-branch for a few days. Seem to have assisted them in landing all the places they wanted at an event mid next month - that's cool, I like being "the fixer". Thinking of rebranding myself as a fixer.

- [Bitsk](bitsk.it) might be accepted into KTH innovation pre-incubation.

In other words, I don't have much of a thesis related update. I should start writing on the literature review...
Agh, I wish KTH would get back to me. Even a "you suck" is better than this silence.

## 4th of January

- Starting to receive replies from community on accepting interview requests. Yet to receive any answers to my survey thou.

- [JK Brekke](http://distributingchains.info/) literally just answered my interview request as I was typing this update.

## 3rd of January

- [POAP](https://poap.xyz) answered my questions and offered support 🎉 my plan of issuing 3 POAPs seems like a plan!

- Finalized the survey.

- Added donate buttons. Yet to figure out how the POAP will be distributed if BTC or LTC is donated.

- Confirmed multisig of Sätra Brunn DAO. Hoping that the insight from the inside will help me do something valuable with my thesis.

- 🎉🎉🎉 First interview 🎉🎉🎉 with <a href="https://twitter.com/filipviz">@filipv.eth</a>. We were supposed to be chatting shortly about <a href="https://juicebox.money/#/">juicebox</a> configuration, but I ended up peppering him about fundamentals of governance. Summary coming soon.

## 2nd of January

- Spent the past couple of days gaining street cred and interacting with the community through [Sätra Brunn DAO](https://www.satrabrunn.xyz).

- Implemented MetaMask connection to the survey.

## 29th of December

- Updated the survey. Put it in its own [repository on github](https://github.com/webel/dao-survey) so I can maybe receive some input.

- Continued reading JK Brekke's [thesis](http://distributingchains.info/).

- Been trying to understand how I can make sense of voting data, like proposals on [snapshot](https://snapshot.org/). For example, [here's a snapshot on etherscan](https://etherscan.io/block/13544840) from a Bankless vote... not sure how to make sense of the block. I've had online stream of graph data in mind... 🤔

- Wrote to JK Brekke on Twitter asking to virtual tea and chat.

- Did another iteration of POAPs. It's fun. Shoot me. Procrastination.

<div class="row">
    <img src="assets/data.png" alt="data">
    <img src="assets/funds.png" alt="funds">
    <img src="assets/knowledge.png" alt="knowledge">
</div>

## 28th of December

- Reading first paper since changing direction from symbolic AI to legitimate DAOs.

Jaya Klara Brekke wrote [DISASSEMBLING THE TRUST MACHINE](http://distributingchains.info/) as a PhD research project. Interestingly, the work seems to tackle what I'm after from the political direction and in a broader sense when looking at blockchain. Completed 2 years ago, it superseeds the recent DAO explosion but it's still giving me a nice foundation to think about comparing "legacy" systems with the emerged decentraliced counterparts.

- Signed up to [Formspark](https://formspark.io/). Seemed pretty nice to get a flatrate for 50k form submissions.

- At some point in the middle of the night I completed a first draft of three POAPs.

![the_poaps](poaps.png)

## 27th of December

- Updated this website with a partial first draft of the survey.

- Added this news page.

## 26th of December

- Added a rule to the Twitter stream, mining data will now include tweets regarding DAOs from accounts that are not verified.

- Requested assistance from POAP;

  I would like to issue three types of POAPs in relation to assisting exploratory research on DAOs. My credentials: I am a master student in Machine Learning at the Royal Institute of Technology (KTH in Stockholm). (Potentially, this will be the first time research might receive a grant from a DAO. Just an interesting FYI.)

  The "events":

  - Completing a survey regarding DAOs. I would like for everyone who completes the survey to receive the POAP, although uncertain if scarcity would gain more traction and interest and therefore more data in the end.
  - Funding the research. Upon completing a transfer to the research wallet address, the funder receives a POAP.
  - Being interviewed in a semi-structured manner. This stage will probably be before the survey stage and will at most be with 20 individuals (maybe one of you peeps could spare some time for research?).

  Am I approaching this correctly from the perspective of POAP issuance?

  Uncertain as to "everyone gets a POAP" with the survey and funding. Could 10k potentially be created? Also, I'd then need a unique code for each POAP for someone to receive when they've signed the survey with metamask...

  Any assistance is greatly appreciated.

## 23th of December

Discussing minting a POAP for participation in a survey, for funding and for interviews with crypto-peeps.

Peeps agree that there should be three separate POAPs.

Joined the POAP discord.
