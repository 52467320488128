---
author:
- |
  [Evita Stenqvist] \
  `evitas@kth.se`
bibliography:
- proposal.bib
title: Gauging Decentralized Autonomous Organisations legitimacy with
  spectral graph analysis
---

# Gauging Decentralized Autonomous Organisations legitimacy with spectral graph analysis

# Background

Recent blockchain technology can broadly be categorised into two main
categories; currencies, and non-fungible tokens (NFTs). The well-known
examples of NFTs relate to digital ownership of art and similar
commodities, sparking the recent craze. A less known use case, at least
in the main stream reporting, is that NFTs are also used as digital
voting rights in decentralised autonomous organisations (DAOs). Crudely,
DAOs are organisations run autonomously by pre-defined rules
programmatically enforced by a publicly known smart contract. For
example, a smart contract could explicitly state how funds
(deterministically) are allocated, or how transactions can be
authorised. Simplified, these DAOs are distributed networks of social,
and financial information flow. As a technology, DAOs aim to provide
transparent digital democracy. The aim of this thesis is to investigate
how the transparency can be leveraged to gauge the legitimacy of such
organisations.

Graph analysis on Decentralised Autonomous Organisations (DAOs) to
measure properties like cliques and conductance on the blockchain
network -- in essence mapping these graph features to measuring
legitimacy of a DAO by analysing their voting graphs. The structure of
these graphs is an underexplored topic, but it is addressed extensively
in social network analysis, and anomaly detection in distributed
systems. Therefore, many of the techniques in these and related areas
are of interest to this thesis. Examples include everything from
spectral graph analysis to neural networks. Similarly, from the
humanities notions of healthy democracies, and work on characterising
corruption will act as inspiration.

I'm in touch with central figures in the DAO community to explore this
further, primarily to gain access to their communication networks which
are often closed servers.

`LOLOL did you really think looking at source would work? :) nice try!
Do drop a line thou if you have input, be nice etc ` are interested in
the thesis and depending on this proposal would like to be the first DAO
to provide a grant to research. The `xxx` members need to vote on the
possibility of a grant and they are internally discussing what they need
to be able to make the decision. Merely this aspect, investing back into
research, is an incentive to them.

Regarding utility, `xxxxx` interest lays in the aspect of being able
to label themselves as a "legitimate" DAO resting on hard evidence.
The thesis would have to propose a framework for what legitimacy in this
context means as well as measuring legitimacy of the network.

Primarily, this project is of interest to me as the author, DAOs have
been at the back of my mind for the past 10 years, seeing a version of
what I deem true technocracy sparks joy, and worry given the number of
scams currently being conducted under the guise of technocratic
governance.

# Research question

-   Can regular online methods for streamed graph data be applied to
    streamed blockchain data? \[simplest type of research question as we
    take a known algorithm and apply to a novel datasource, blockchains,
    which at first glance should behave similarly to regular graphs\]

-   In turn, can the properties (number of triangles, clustering
    coefficient, ...) be used to derive gauges for legitimacy for a
    DAO?

-   What would legitimacy in a DAO be? Why? (e.g. compounding Active
    voting ratio with something?)

-   What properties would a best case scenario artificial graph have?

These research questions have parallels to the topics of social network
analysis, and anomaly detection in distributed systems. Therefore, a
literature study will investigate to what extent the above research
questions have corresponding research in other fields.

# Hypothesis

Regular graph theory can be applied to a stream of blockchain data,
although expect to see suprising differences to properties of regular
social media networks due to the underlying incentive between the two
differing (one to govern assets/whatever they govern, the other to share
and consume data).

# Research method

Depending on the limitations that will be set on the paper (the idea of
defining a framework for legitimacy might be too much for a thesis?) a
new dataset will be collected. Questions like *"what was the first
DAO?"*, *"how is soundness of a DAO measured?"*, *"why?"* are best
answered by the community. At this point in time, there are no straight
answers to these questions. Interviews with prominent figures in the
space is also a possibility and something that's lined up, even if not
strictly necessary for the paper, the deeper understanding will be
invaluable.

The main method will be with model experimentation on blockchain data.
Possibly also Discord data depending on access. The most elementary
method could be comparing properties of a blockchain stream with other
types of networks to gather what they most mimic.

# Background of the student

Relevant courses

-   ID2222 - Data Mining.

-   DD2418 - Language Engineering.

-   DD2448 - Foundations of Cryptography

-   Potentially also Neural Networks and Pattern Recognition.

I have worked as a startup programmer for the past 6 years. AKA, I can
quickly setup an experiment to verify its usefulness under harsh time
constraints. Having been in the crypto space since 2012, where I have
useful contacts and experience with all aspects of the technology stack,
such as smart contracts, Web3 authentication, and general blockchain
knowledge, I have a general enough foundation for carrying out research
in the space.

----
*Removed several headings for brevity:*

- Supervisor at the company/external organization
- Suggested examiner at KTH
- Suggested supervisor at KTH
- Resources
- Eligibility
- Study Planning
- Appendix
- Acronyms
