<script>
  export let onclick;
</script>

<nav>
  <ul>
    <li>
      <a on:click={() => onclick("paper")} href="#paper"> Paper</a>
    </li>
    <li>
      <a on:click={() => onclick("survey")} href="#survey"> Survey </a>
    </li>
    <li>
      <a on:click={() => onclick("news")} href="#news"> News </a>
    </li>
  </ul>
</nav>

<style>
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style-type: none;
    padding-left: unset;
  }
  a {
    text-transform: uppercase;
    cursor: pointer;
  }
</style>
