<script>
  export let className;
  import { slide } from "svelte/transition";
  import Tooltip from "./Tooltip.svelte";
  import questions from "../survey/survey.json";
  import Collapsible from "./Collapsible.svelte";
  import Loading from "./Loading.svelte";

  let loading = false;
  let wantEmail = false;
  let ethAddress = "";
  let form;
  let url;
  let submitted;

  const requiredQuestions = questions.filter((q) => q.required);
  const optionalQuestions = questions.filter((q) => !q.required);

  const Inputs = {
    TextArea: "textarea",
  };

  function signWithEthereum() {
    window.ethereum.request({ method: "eth_requestAccounts" }).then((data) => {
      ethAddress = ethereum.selectedAddress;
    });
  }

  async function setPoapClaimUrl() {
    const body = JSON.stringify({ eth: ethAddress });
    return fetch(
      "https://hook.integromat.com/xgbsucragvwpw8hsnxrw7cgvr9s2qzyo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        loading = false;
        url = json.url;
      });
  }

  function onSubmit() {
    const data = new FormData(form);
    const body = JSON.stringify(Object.fromEntries(data.entries()));
    loading = true;
    fetch("https://submit-form.com/Hm1BF5OT", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body,
    }).then((response) => {
      loading = false;
      if (response.ok) {
        if (!ethAddress) {
          submitted = true;
        } else {
          loading = true;
          return setPoapClaimUrl();
        }
      }
    });
    return false;
  }
</script>

<section class={className}>
  <h1 class="title">THE SURVEY</h1>
  <aside>
    <p>
      Be apart of the exploratory research by answering the below questions, as
      a thank you; you'll be able to claim a POAP on
      <Tooltip title="If something goes awry, dm me on twitter or email."
        >submission!</Tooltip
      >
    </p>
    <p>
      The data collected from this survey will become licensed and free to use
      for you as a <Tooltip
        title={`I have a <i>Distributed Data Collective</i> idea for a DAO where the received
        POAP acts as the membership, that will be far off unless natural contributors
        turn up.`}
        >POAP holder.
      </Tooltip>
    </p>
    <p>
      {#if !window.ethereum}
        <p>
          <b>I can't give you a POAP!</b> MetaMask cannot be detected, either
          switch to a MetaMask enabled enviroment, or get the extension
          <a href="https://metamask.io/">MetaMask</a>, or email me with your
          wallet provider and if you're lucky I can quickly add support.
        </p>
      {/if}
    </p>
    <p>
      If you have input regarding the questions - either
      <a href="mailto:thesis@viciously.co">email</a>
      or go open an issue on the
      <a href="https://github.com/webel/dao-survey">public repository</a>.
    </p>
  </aside>
  {#if window.ethereum}
    {#if ethAddress}
      <p><b>Thank you! 🎉</b></p>
    {:else}
      <p>
        <b>
          MetaMask detected! Connect to fill the address you want to receive
          your POAP at.
        </b>
      </p>
    {/if}
    <div class="metamask">
      <input disabled bind:value={ethAddress} />
      <button on:click={signWithEthereum}
        >{ethAddress ? "Connected" : "Connect to MetaMask"}</button
      >
    </div>
  {/if}
  {#if url}
    Don't forget to claim your POAP: <a href={url}
      >{url}</a
    >
  {:else if submitted}
    <p><b>Thank you! 🎉</b></p>
  {:else if loading}
    <Loading />
  {:else}
    <form bind:this={form} on:submit|preventDefault={onSubmit}>
      {#each requiredQuestions as question, index}
        <label for={`required-${index}`}
          >{question.label}
          {#if question.required}
            <b class="required">*</b>
          {/if}
        </label>
        <span>{@html question.help}</span>
        {#if question.input === Inputs.TextArea}
          <textarea
            id={`required-${index}`}
            name={`required-${index}`}
            required={question.required}
          />
        {/if}
      {/each}

      <input
        type="checkbox"
        bind:checked={wantEmail}
        name="consent"
        id="consent"
      />
      Would you like to receive sporadic emails regarding the thesis, including an
      analysis of this data?
      {#if wantEmail}
        <input
          transition:slide
          type="email"
          name="email"
          id="email"
          placeholder="Your email here"
          required={wantEmail}
        />
      {/if}
      <Collapsible title="Feeling generous? Up for more questions?">
        {#each optionalQuestions as question, index}
          <label for={`optional-${index}`}>{question.label} </label>
          <span>{@html question.help}</span>
          {#if question.input === Inputs.TextArea}
            <textarea
              id={`optional-${index}`}
              name={`optional-${index}`}
              required={question.required}
            />
          {/if}
        {/each}
      </Collapsible>

      <input type="hidden" name="ethAddress" value={ethAddress} />
      <input type="submit" />
    </form>
  {/if}
</section>

<style>
  aside {
    background-color: rgb(255 62 0 / 10%);
    border-radius: 5px;
    margin: 0 auto;
    margin-top: -3rem;
    max-width: 500px;
    padding: 1rem;
  }

  button {
    background: var(--orange);
    color: antiquewhite;
  }
  input[type="submit"] {
    background-color: var(--orange);
    border-radius: 5px;
    color: antiquewhite;
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  input[type="email"] {
    width: 100%;
    margin-top: 0.5rem;
  }
  textarea {
    width: 100%;
    margin-top: 0.5rem;
  }
  label {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  span {
    font-size: small;
  }

  .metamask {
    display: flex;
    width: 100%;
  }

  .metamask input {
    width: 70%;
  }

  .required {
    color: var(--orange);
  }
</style>
