<script>
  import Modal, { getModal } from "./Modal.svelte";

  const btc = "bc1qeg28rhmezry6r0ena4kw5hcl07u7v72ln772ap";
  const ltc = "LaApSfTEMa7WWbR2XaRcZ8CTLeJm15PhSt";
  const eth = "0x5E744732c23aEA95fa95102C83e5Ae35d79e7D19";

  function copy(input) {
    const copyText = document.getElementById(input);
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }
</script>

<section>
  <b>Support by donating</b>
  <img
    on:click={() => getModal("ltc").open()}
    src="./assets/litecoin-ltc-logo.svg"
    alt="Litecoin Donate Button"
  />
  <img
    on:click={() => getModal("btc").open()}
    src="./assets/bitcoin-btc-logo.svg"
    alt="Bitcoin Donate Button"
  />
  <img
    on:click={() => getModal("eth").open()}
    src="./assets/ethereum-eth-logo.svg"
    alt="Ethereum Donate Button"
  />
  <b>Receive a POAP!</b>
</section>

<Modal id="ltc">
  <div class="modal-content">
    <h1>Donate LTC</h1>
    <img src="./assets/ltc-qr.png" alt="Litecoin QR" />
    <input id="ltc" disabled value={ltc} />
    <button on:click={() => copy("ltc")} class="copy-container">
      <img alt="Copy" class="copy" src="./assets/copy.svg" /> Copy
    </button>
    <p>
      <a href="mailto:thesis@viciously.co">Email</a> an eth address to get
      whitelisted for your POAP! <br />Don't forget to add the LTC address you
      sent funds from.
    </p>
  </div>
</Modal>

<Modal id="btc">
  <div class="modal-content">
    <h1>Donate BTC</h1>
    <img src="./assets/btc-qr.png" alt="Bitcoin QR" />
    <input id="btc" disabled value={btc} />
    <button on:click={() => copy("btc")} class="copy-container">
      <img alt="Copy" class="copy" src="./assets/copy.svg" /> Copy
    </button>
    <p>
      <a href="mailto:thesis@viciously.co">Email</a> an eth address to get
      whitelisted for your POAP! <br />Don't forget to add the BTC address you
      sent funds from.
    </p>
  </div>
</Modal>

<Modal id="eth">
  <div class="modal-content">
    <h1>Donate ETH</h1>
    <img src="./assets/eth-qr.png" alt="Ethereum QR" />
    <input id="eth" disabled value={eth} />
    <button on:click={() => copy("eth")} class="copy-container">
      <img alt="Copy" class="copy" src="./assets/copy.svg" /> Copy
    </button>
  </div>
</Modal>

<style>
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-content img {
    height: unset;
    width: 300px;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    margin: 1rem;
    height: 50px;
    cursor: pointer;
  }
  input {
    width: 100%;
  }
  img.copy {
    width: 20px;
    height: 20px;
    margin: 0.5rem;
  }

  .copy-container {
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
  }
</style>
