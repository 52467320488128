# Revealing hidden social structures in Decentralized Autonomous Organisations

***Keywords---*** blockchain, spectral graph analysis, decentralised
autonomous organisations

## BACKGROUND & OBJECTIVE

Blockchain technology can broadly be categorised into two main
categories; interchangeable units (tokens, or currencies), and unique
tokens (non-fungible tokens - NFTs). Both these two categories have a
use case that main stream reporting often overlook; voting rights in
Decentralised Autonomous Organisations (DAOs). Crudely, DAOs are
organisations consisting of members that share a joint goal and a joint
financial account. The direction of the DAO is enforced by a smart
contract, the underlying technology which lives on a blockchain (most
notably Ethereum), that acts in a deterministic fashion according to
the wishes of the DAOs members. Due to the transparency propert of the
blockchain, the chosen direction is also verifiable by the organisations
members and by outsiders. For example, the members could decide to
allocate funds to an internal project each month, or make an external
investment, or to bring on new members, etc.

Simplified, these DAOs are distributed networks of social, and financial
information flow. As a technology, DAOs aim to provide transparent
digital democratic processes standing on the foundation of trustless
global collaborations.

The definition of a DAO most commonly starts with "usually flat, and
fully democratized" [@ethereum.org] as opposed to a traditional
organisation which is usually hierarchical and sometimes governed by
hidden mechanisms (e.g., historical power structures, corruption, et
c.). However, the transparent information which is stored on the
blockchain for these DAOs has yet to be used to verify this claim, and
the structure of these networks is an underexplored topic.

I hypothesis that there exist hidden social structures in DAOs that can
be revealed with the same methods that have been used in social
networks. The awareness of these structures strengthen the democratic
process and the underlying requirement of transparency of these
organisations. That is the main objective - to strengthen the
organisations, and provide tools to analyse these and future questions
related to the structures of DAOs.

Primarily, this project is of interest to me as the author, DAOs have
been at the back of my mind for the past 10 years, seeing a version of
what I deem true technocracy sparks joy, and worry given the number of
scams currently being conducted under the guise of technocratic
governance.

## RESEARCH QUESTION & METHOD

### Research Question

-   How can DAO voting data and members transactional relationships be
    used to construct a graph that can in turn be used to reveal social
    structures within DAOs using spectral clustering?

#### Objectives

-   Identify DAO members. **Task:** By using voting data, as voting data
    includes the addresses that cast a vote. The ratio of known members
    that are active in the DAO can then be derived by comparing to the
    total number of token holders on etherscan.io [@etherscan].
    Potentially disregard studying DAOs that have too low a ratio of
    known members.

-   Represent the relationship of the known DAO members. **Task:**
    Construct a graph weighted on the number of times members have
    interacted (e.g. sent a currency as payment) with each other
    on-chain.

-   Cluster the known DAO members depending on their relationships.
    **Task:** Given the constructed graph, run a spectral clustering
    algorithm [@ng2001spectral] (as we are interested in connectivity
    and not distance) to find clusters of members.

-   Qualitative study of structures in select DAOs. **Task:** For a
    subset of the discovered structures, e.g. for subgraphs that
    recieves lots of funding from a DAO, do a in-depth analysis with
    complementing information gathered from, e.g., interviews with
    members.

### Method

By using the commonly used service Snapshot [@snapshot], DAO members
will be found by iterating through proposals and the votes which include
the members addresses.

The relationship of these members will then be encoded into a graph by
examining each members transaction history on the Ethereum blockchain,
each member is a node, and if a member has transacted with another
member an edge between these nodes is added. If they have transacted
more than once, the weight of the edge is incremented. How this
weighting will be incremented, linearly or by some function that takes
some other parameter into account - I do not know yet.

Given the graph, a spectral clustering algorithm (or some other
connectivity clustering algorithm) will be run to derive clusters in the
DAOs. As the DAOs main account is known (it turns out to be a nice side
effect from using Snapshot \[4\] as a the ID is the main address) the
transactions between the clusters and the DAO can also be derived.

### Limitations

Only DAOs who conduct their voting through Snapshot \[4\] will be
studied, which in turn means only Ethereum-based DAOs. Snapshot is
the most widely used voting tool used by DAOs as it saves fees by not
being fully on-chain, but the service still upholds a level of
decentralisation by using IPFS (a peer-to-peer filesystem). Beyond
being the most widely used tool, it has an intuitive developer
interface.

Further, only token-share type voting will be considered, partially
because it is the most common voting strategy, and partially because it
is the most controversial [@buterin_2021].

## Risks

There are no inter-member transactions, and therefor no way of
clustering the members by this metric. Although, a pivot if this turns
out to be the case, is to cluster members depending on the protocols
they interact with and/or the DAOs they share membership of.

## EVALUATION & NEWS VALUE

Since the hypothesis is that there will be clusters representing hidden
structures, we can evaluate this in a variety of ways. First,
non-trivial clusters produced by the spectral clustering indicates that
there are structures. There are multiple quantitative metrics for
measuring the outcome of clustering algorithms, related to, e.g., how
pronounced the clusters are, such as silhouette
index [@tan2016introduction]. Secondly, the qualitative studies of
select cases can reveal whether these structures are hidden in the DAO
governance or merely a reflection of known groups or hierarchies.
Regardless of whether the structures were hidden or known, the
ecological validity of the method will be strengthened if the clusters
represent real world governance structures.

The work is scientifically relevant as it proposes a method for
constructing a social graph from ledger data, and applies a known,
possibly modified, algorithm to a novel datasource.

It is further valuable to the DAO community at large as it furthers the
fundamental idea of transparency. For a broader audience, the methods
devised in this thesis can help combat scams and a safer cryptocurrency
environment.

## PRE-STUDY

As the paper merges the idea of social network analysis with blockchain
transactions a literature study of social network analysis will be
conducted, with the aim to identify what can be derived from graph
analysis on social networks and what specific social structures entail
for an organisation.

Online connectivity clustering methods will be analysed to find an
approach that can either be tweaked, or is already appropriate, to
cluster members by a stream of transaction data. It is important that
the clustering method can handle large amounts of data, as DAOs can have
a large number of members and many transactions. Therefore an online
method is preferable.

## CONDITIONS & SCHEDULE

-   **snapshot.org** the datasource for finding DAO members.

-   **Ethereum** the blockchain where the transactions by DAO members
    will be found. A service such as etherscan, or Infura, depending on
    the necessery amount of requests will be used.

-   **Further interviews with prominent DAO members** I will continue to
    speak to prominent figures in these spaces to understand the
    implications of unknown sub-communities within DAOs.

I am currently still impacted by post-covid and struggling to juggle my
usual 80% work and 100% study schedule. Yet, I am not completely
discouraged quite yet, and still hope that I can be done by mid-June to
defend. Assuming I can be back to my usual tempo by March 20th;

-   Background & Literature Study done by March 29th.

-   Methodology done by April 7th.

-   Results done by May 1st.

-   Discussion done by May 10th.

-   Draft done by May 20th.

-   Final version ready to be sent to (?) by May 30th.

-   Defend mid-June.

Note that my data-collection has already begun, and that I have priorly
implemented a spectral graph clustering algorithm (although I am fairly
certain that there exists better implementations in a library).
