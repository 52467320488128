<script>
  import LeftPanel from "./LeftPanel.svelte";
  import RightPanel from "./RightPanel.svelte";
</script>

<main>
  <LeftPanel />
  <RightPanel />
</main>

<style>
  main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }

  @media (min-width: 640px) {
    main {
      flex-direction: row;
      justify-content: space-evenly;
      max-width: none;
      overflow: hidden;
    }
  }
</style>
