<script>
  import Proposal from "../paper/proposal-redacted.svx";
  import Plan from "../paper/plan.svx";
  export let className;
</script>

<section class={`markdown ${className}`}>
  <h1 class="title">THE PLAN</h1>
  <Plan />
  <aside>
    <b>Note:</b> The above plan only loosly corresponds to my haphazard proposal
    (below) from back in December. I know more now, for example; erc20 tokens can
    be used to vote too! Although, I'm leaving my initial proposal here.
  </aside>
  <h1 class="title">THE PROPOSAL</h1>
  <Proposal />
</section>

<style>
  aside {
    background: rgb(255 62 0 / 10%);
    border-radius: 5px;
    margin-top: 4rem;
    padding: 1rem;
  }
</style>
