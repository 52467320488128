<script>
  export let title = "";
  let isHovered = false;
  let x;
  let y;

  function mouseOver(event) {
    isHovered = true;
    x = event.pageX + 5;
    y = event.pageY + 5;
  }
  function mouseMove(event) {
    x = event.pageX + 5;
    y = event.pageY + 5;
  }
  function mouseLeave() {
    isHovered = false;
  }
</script>

<span
  class="element"
  on:focus={mouseOver}
  on:mouseover={mouseOver}
  on:mouseleave={mouseLeave}
  on:mousemove={mouseMove}
>
  <slot />
</span>

{#if isHovered}
  <div style="top: {y}px; left: {x}px;" class="tooltip">{@html title}</div>
{/if}

<style>
  .element {
    cursor: pointer;
    background-color: rgb(255 62 0 / 10%);;
    padding: 1px 5px;
    border-radius: 5px;
    border: 1px var(--orange) dashed;
  }
  .tooltip {
    border: 1px solid #ddd;
    box-shadow: 1px 1px 1px #ddd;
    background: white;
    color: var(--orange);
    border-radius: 4px;
    padding: 4px;
    position: absolute;
  }
</style>
