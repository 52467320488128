<script>
  import Navigation from "./Navigation.svelte";
  import News from "./News.svelte";
  import Paper from "./Paper.svelte";
  import Survey from "./Survey.svelte";

  let activeTab = document.location.hash.replace("#", "") || "paper";

  function anchorClick(tab) {
    activeTab = tab;
  }
</script>

<section>
  <Navigation onclick={anchorClick} />
  <article>
    <Paper className={activeTab !== "paper" && "hidden"} />
    <Survey className={activeTab !== "survey" && "hidden"} />
    <News className={activeTab !== "news" && "hidden"} />
  </article>
  <footer>©️ 2022 Evita Stenqvist</footer>
</section>

<style>
  article {
    margin: 0rem 2rem 2rem;
    max-width: 900px;
    min-height: 82vh;
  }
  @media (min-width: 640px) {
    section {
      overflow-y: scroll;
    }
  }
</style>
