<script>
  import Donate from "./Donate.svelte";
</script>

<section>
  <header>
    <h1>DAO Thesis</h1>
    <img alt="A crypto bull puking rainbow" src="./bullpuke.png" />
  </header>
  <p>
    Do you know stuff about DAOs? Are you interested in contributing to
    research?
  </p>
  <p>I'm writing my thesis, let's speak!</p>
  <a href="mailto:thesis@viciously.co">thesis@viciously.co</a>

  <p>My previous work:</p>
  <a
    href="https://www.diva-portal.org/smash/record.jsf?pid=diva2%3A1110776&dswid=-183"
    >Predicting Bitcoin price fluctuation with Twitter sentiment analysis</a
  >
  <Donate />
</section>

<style>
  section {
    text-align: center;
    padding: 1em;
    align-self: center;
    max-width: 250px;
  }
  header {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 20%;
  }
  img {
    min-width: 200px;
  }

  h1 {
    color: #ff3e00;
    text-align: center;
    text-transform: uppercase;
    font-size: 3em;
    font-weight: 100;
  }

  @media (min-width: 1100px) {
    section {
      max-width: unset;
    }
    h1 {
      font-size: 4em;
    }
  }
  @media (min-width: 640px) and (max-width: 1200px) {
    section {
      max-width: 350px;
    }
  }
</style>
